<template>
  <div class="DustPoliceInfo">
    <h2>报警信息</h2>
    <div class="searchWrap">
      <el-row>
        <el-col :span="4">
          <div class="sb">
            <span>设备厂商</span>
            <el-select
              v-model="manufacturerId"
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option
                v-for="item in manufacturerList"
                :key="item.id"
                :label="item.manufacturerName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sb">
            <span>设备</span>
            <el-select
              v-model="deviceSn"
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option
                v-for="item in deviceList"
                :key="item.id"
                :label="item.deviceName"
                :value="item.deviceSn"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sb">
            <span>报警种类</span>
            <el-select
              v-model="alertType"
              class="searchInput"
              size="small"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="(item, index) in alertTypeList"
                :key="index"
                :label="item.name"
                :value="item.field"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sb">
            <span>查询范围</span>
            <el-select
              v-model="reportingType"
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option
                label="当日数据"
                :value="1"
              >
              </el-option>
              <el-option
                label="历史数据"
                :value="2"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="4" v-if="reportingType == 2">
          <div class="sb">
            <span>开始日期</span>
            <el-date-picker
              v-model="startTime"
              type="date"
              value-format="yyyy-MM-dd"
              class="searchInput"
              size="small"
              placeholder="选择日期">
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="4" v-if="reportingType == 2">
          <div class="sb">
            <span>结束日期</span>
            <el-date-picker
              v-model="endTime"
              type="date"
              value-format="yyyy-MM-dd"
              class="searchInput"
              size="small"
              placeholder="选择日期">
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="1">
          <el-button type="primary" size="small" @click="onGetListData"
            >搜索</el-button
          >
        </el-col>
      </el-row>
    </div>
    <el-table :data="listData" style="margin-top: 20px">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="deviceSn" label="设备SN码"></el-table-column>
      <el-table-column prop="deviceName" label="设备名称"></el-table-column>
      <el-table-column prop="manufacturerName" label="设备厂商"></el-table-column>
      <el-table-column prop="alertType" label="报警种类"></el-table-column>
      <el-table-column prop="alarmThreshold" label="报警阈值"></el-table-column>
      <el-table-column prop="currentValue" label="报警值"></el-table-column>
      <el-table-column prop="createTime" label="上报时间"></el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="totals"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onGetListData"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 200, 500]"
    >
    </el-pagination>
  </div>
</template>
    
<style lang="less" scoped>
.DustPoliceInfo {
  padding: 20px;
}
</style>
    
<script>
export default {
  name: "DustPoliceInfo",
  components: {},
  data() {
    return {
      deviceList: [],
      manufacturerList: [],
      deviceSn: "",
      alertType: "",
      manufacturerId: "",
      reportingType: 1,
      // alarmType: 1,
      startTime: "",
      endTime: "",
      pageSize: 10,
      pageNum: 1,
      totals: 0,
      listData: [],
      alertTypeList: [],
    };
  },
  computed: {},
  watch: {
    reportingType: function (newVal, oldVal) {
      this.onGetListData()
    },
    manufacturerId: function (newVal, oldVal) {
      this.onGetDeviceList()
    },
    deviceSn: function (newVal, oldVal) {
      this.deviceList.forEach(item => {
        if (item.deviceSn == newVal) {
          this.manufacturerId = item.manufacturerId
        }
      });
      this.onGetListData()
      this.onGetAlertTypeList()
    },
    alertType: function (newVal, oldVal) {
      this.onGetListData()
    },
  },
  mounted() {
    this.onGetDeviceList()
    this.onGetManufacturerList()
    this.onGetListData()
    this.onGetAlertTypeList()
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取厂商列表
    onGetManufacturerList: function () {
      var that = this;
      var data = {};
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/dustDevice/getManufacturers",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.manufacturerList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取设备列表
    onGetDeviceList: function () {
      var that = this;
      var data = {
        pageSize: 0,
        pageNum: 1,
        projectId: localStorage.getItem("projectId"),
        manufacturerId: this.manufacturerId,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/dustDevice/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.deviceList = res.data.dataList;
            if (res.data.dataList.length) {
              that.deviceSn = res.data.dataList[0].deviceSn
            } else {
              that.deviceSn = ""
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取报警种类列表
    onGetAlertTypeList: function () {
      if (!this.deviceSn) return
      var that = this;
      var data = {
        deviceSn: this.deviceSn,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/dustDevice/getRule",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.alertTypeList = res.data.rule
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取主列表数据
    onGetListData: function () {
      var that = this;
      var data = {
        projectId: localStorage.getItem('projectId'),
        deviceSn: this.deviceSn,
        manufacturerId: this.manufacturerId,
        reportingType: this.reportingType,
        alertType: this.alertType,
        startTime: this.startTime,
        endTime: this.endTime,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/dustDevice/alarmData",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.listData = res.data.dataList;
            that.totals = res.data.totals;
          } else {
            that.listData = [];
            that.totals = res.data.totals;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    
    // 切换分页大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.onGetListData();
    },
  },
};
</script>